<template>
    <b-container class="text-center">
        <div class="d-flex justify-content-center mb-1">
            <b-spinner variant="success" label="Spinning" block v-if="loading"
                style="width: 3rem; height: 3rem"></b-spinner>
        </div>
        <div v-if="!loading">
            <div class="select text-center">
                <h3 class="text-primary">Customer chosen for the current service request</h3>
            </div>

            <b-form-select v-model="leadClientId" :options="optionsCustomer" disabled />
        </div>

    </b-container>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";
import {
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BContainer,
    BFormSelect,
} from "bootstrap-vue";
export default {
    components: {
        BFormTextarea,
        BFormSelect,
        BContainer,
        BInputGroup,
        BFormGroup,
        BFormInput,
        BInputGroupPrepend,
        BSpinner,
        BInputGroupAppend,
    },
    data() {
        return {
            selected: "",
            optionsCustomer: [],
        };
    },
    props: {
        leadClientId: {
            type: String,

        },
    },
    computed: {
        ...mapGetters("customerModule", {
            CustomerList: "customers",
            custId: "custId",
            loading: "loading",
        }),
    },
    methods: {
        ...mapActions("customerModule", ["getCustomersListAction", "getCustomersByIDAction"]),

        customerSelected() {
            this.$emit("customerSelected", this.leadClientId);
        },
    },
    async mounted() {
        await this.getCustomersListAction().then(() => {
            this.CustomerList.map((v, i) =>
                this.optionsCustomer.push({
                    value: v.id,
                    text: `${v.otherName}`,
                })
            );
        });
    },
};
</script>

  