<template>
  <section class="invoice-add-wrapper">
    <div class="d-flex justify-content-center mb-1">
      <b-spinner variant="success" label="Spinning" block v-if="loading" style="width: 3rem; height: 3rem"></b-spinner>
    </div>

    <b-form @submit.prevent="submitForm" @change="submitFormText" v-if="!loading">
      <b-row class="invoice-add">
        <b-col cols="12" xl="12" md="12">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div class="d-flex justify-content-end print_button">
                <!-- Add this button inside your template -->
                

                <b-button variant="primary" @click="print">Print</b-button>

                <b-button variant="success" @click="downloadAsDoc" class="mx-3">Download as Word</b-button>



                <!-- Add this button inside your template -->


              </div>
              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                <!-- Header: Left Content -->
                <!-- <Logo /> -->

                <!-- Header: Right Content -->

                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title mr-1">Reference</h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input id="invoice-data-id" v-model="templateId.reference" readonly />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">Start Date: </span>
                    <flat-pickr v-model="templateId.startDate" disabled class="form-control invoice-edit-input" />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title"> Due Date: </span>
                    <flat-pickr v-model="templateId.dueDate" required disabled class="form-control invoice-edit-input" />
                  </div>
                </div>
              </div>
            </b-card-body>
            <b-card-body class="invoice-padding pb-0 sale_person">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col cols="12" md="6" class="mt-md-0 mt-3 d-flex align-items-center" order="2" order-md="1">
                  <label for="invoice-data-sales-person" class="text-nowrap mr-50">Staff:</label>
                  <b-form-select v-model="templateId.assignedTo" :options="optionsSalesPerson" required disabled />
                </b-col>

                <!-- Col: Total -->
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <h4 class="my-2 d-flex justify-content-start mx-3">
              <span>{{ product.description }}</span>
            </h4>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section" style="text-align: left">
              <div v-html="renderedTemplate"></div>
              <!-- <b-form-textarea v-model="templateId.template" rows="30" readonly /> -->
            </b-card-body>

            <b-card-body class="invoice-padding form-item-section note-section" title="Note for Service Request"
              style="text-align: left">
              <div v-html="renderedTemplateNote" readonly></div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <!-- Right Col: Card -->

    <!-- Action Buttons -->
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BFormSelect,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BSpinner,
  VBToggle,
} from "bootstrap-vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BSpinner,
    BFormSelect,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },

  computed: {
    ...mapGetters("productModule", {
      product: "productID",
      productLoading: "loading",
    }),
  },
  data() {
    return {
      config: {
        wrap: {
          wrap: true,

          minDate: new Date(),
        },
      },
      configDue: {
        wrap: {
          wrap: true,

          minDate: new Date(),
        },
      },
      startDate: new Date(),
      dueDate: null,
      textAreaRows: 1,
      salesPerson: "",
      optionsSalesPerson: [],
      template: {
        name: "",
        startDate: new Date(),
        dueDate: null,
        salesPersonId: "",
        text: "",
        description: "",
      },
    };
  },

  props: {
    templateId: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
    ...mapGetters("productModule", {
      product: "productID",
      productLoading: "loading",
    }),
    renderedTemplate() {
      return this.templateId.template.replace(/\n/g, "<br>");
    },
    renderedTemplateNote() {
      return this.templateId.notes;
    },
  },
  async mounted() {
    await this.getSalesPersonListAction().then(() => {
      this.SalesPersonList.map((v, i) =>
        this.optionsSalesPerson.push({
          value: v.id,
          text: `${v.firstName}` + " " + `${v.lastName}`,
        })
      );
    });
    await this.getProductIDAction(this.templateId.id).then(() => {
      this.template.description = this.product.description.replace(
        /\n/g,
        "<br>"
      );
      this.template.description = this.template.description.innerHTML;
    });
    this.submitFormText();
  },

  methods: {
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    ...mapActions("productModule", ["getProductIDAction"]),

    submitFormText() {
      this.$emit("onchange", this.templateId);
    },
    getSelectedSalesPerson(event) {
      this.template.salesPersonId = event;
    },
    updateTextAreaRows() {
      const newlines = (this.productID.letterDescription.match(/\n/g) || []).length;
      this.textAreaRows = newlines + 1;
    },
    preview() {
    const previewWindow = window.open("", "_blank");
    previewWindow.document.write("<html><head><title>Preview</title></head><body>");
    previewWindow.document.write(this.renderedTemplate);
    previewWindow.document.write("</body></html>");
  },
    sanitizeHTML() {
      return (html) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = html;
        console.log(tempElement.innerHTML);
        return tempElement.innerHTML;
      };
    },
    downloadAsDoc() {
      const renderedTemplate = this.renderedTemplate;
      const renderedTemplateNote = this.product.description;
      const blobData = [
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:w="urn:schemas-microsoft-com:office:word" xmlns="http://www.w3.org/TR/REC-html40">',
        '<head><meta charset="utf-8"><title>Export HTML To Doc</title></head><body>',
        '<div>',
        `<h2>${renderedTemplateNote}</h2>`, 
        `<br>`,
        renderedTemplate,
        '</div>',
        '</body></html>'
      ];
      const blob = new Blob(blobData, { type: "application/msword;charset=utf-8" });
      const a = document.createElement("a");
      a.style.display = "none";
      a.download = `${this.template.name}.doc`;
      a.href = URL.createObjectURL(blob);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    print() {
      window.print();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.text-area-auto-height {
  height: auto;
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>

<!-- Add this style section inside your template -->
<style lang="scss">
@media print {
  a[href]:after {
    content: none !important;
  }

  html,
  body {
    height: 99% !important;
  }

  a[href]:after {
    content: none !important;
  }

  /* Hide unnecessary elements */
  .no-print,
  nav,
  .print_button {
    display: none !important;
  }

  /* Set font size and color */
  body {
    font-size: 12pt;
    color: #000;
  }

  /* Remove borders */
  * {
    border: none !important;
    outline: none !important;
  }

  // Global Styles
  body {
    background-color: transparent !important;
    font-size: 18px !important;
  }

  .flatpickr-input {
    font-size: 18px !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none !important;
  }

  textarea[readonly] {
    color: #000 !important;
    opacity: 1 !important;
  }

  input[readonly] {
    color: #000 !important;
    opacity: 1 !important;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  .content-header {
    display: none !important;
  }

  .invoice-edit-input {
    border: none;
  }

  .wizard-card-footer {
    display: none !important;
  }

  .wizard-nav {
    display: none !important;
  }

  .print_button {
    display: none !important;
  }

  .sale_person {
    display: none !important;
  }

  .note-section {
    display: none !important;
  }


  .invoice-number-date {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            >.col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
