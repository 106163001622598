<template>
    <div>
       
        <form-wizard color="#B1D06C" :title="null" :subtitle="null" shape="square" finish-button-text="Ok"
            back-button-text="Previous" class="mb-3" @on-complete="formSubmitted" ref="formWiz">
            <!-- Search customer tab -->
      
            <tab-content title="View Customer">
                <SelectCustomer :leadClientId="serviceOne.leadClientId" />
            </tab-content>
            <tab-content title="View Service">
                <SelectTemplate  :serviceId="serviceOne.itemId" />
            </tab-content>
            <tab-content title="View template">
                <b-container class="text-center">
                    <TemplateView :templateId="serviceOne" @onchange="template" />
                </b-container>
            </tab-content>
            <tab-content title="View Price">
                <Price :product="serviceOne" @price="price" />
            </tab-content>

        </form-wizard>
    </div>
</template>
  
<script>
import SelectCustomer from './selectCustomer.vue'
import SelectTemplate from './SelectTemplate.vue'
import TemplateView from './TemplateView.vue'
import Price from './Price.vue'
import { FormWizard, TabContent } from "vue-form-wizard";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { BRow, BCol, BButton } from "bootstrap-vue";
import { codeIcon } from "./LeadsWizard.js";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        FormWizard,
        BButton,
        SelectCustomer,
        SelectTemplate,
        TemplateView,
        Price,
        TabContent,
        BRow,
        BCol,
        ToastificationContent,
    },
    data() {
        return {
            codeIcon,
            customerId: "",
            templateId: "",
            templateData: {
                name: null,
                startDate: null,
                dueDate: null,
                assignedTo: null,
                template: null,
            },
            templatePrice: {
                salePrice: null,
                additionalCost: null,
            }
        };
    },
    computed: {
        ...mapGetters("productModule", {
            productID: "productID",
            loading: "loading",
        }),

        ...mapGetters("serviceModule", {
            serviceOne: "serviceOne",
            loading: "loading",
        }),
    },
    async mounted() {
       
        await this.getServiceRequestsByIDAction(this.$route.params.id);
    },
    methods: {
        ...mapActions("productModule", ["getProductIDAction"]),
        ...mapActions("serviceModule", ["addServiceRequestsAction", "getServiceRequestsByIDAction", "updateServiceRequestAction"]),
        //route pages to leads

        customerSelected(customerSelected) {
            this.customerId = customerSelected;
        },
      
        template(template) {
            console.log(template);

            this.templateData.name = template.name;
            this.templateData.startDate = template.startDate;
            this.templateData.dueDate = template.dueDate;
            this.templateData.assignedTo = template.assignedTo;
            this.templateData.template = template.template;
        },

        price(price) {
            this.templatePrice.salePrice = price.NewPrice;
            this.templatePrice.additionalCost = price.additionalCost;
        },
        formSubmitted() {
            this.$router.push({ name: "request-list" });
   
          
        },
    },
};
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
  